import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import BgDark from "@public/sticky-header-bg-dark.svg";
import BgLight from "@public/sticky-header-bg-light.svg";
import { ITheme } from "@lib/themes/types";

const useStyles = makeStyles((theme: ITheme, props) => ({
  root: {
    position: "relative",
    padding: "24px 80px",
    background: theme.palette.stickyHeader.background,
    width: "100%",
    overflow: "hidden",
    backdropFilter: "blur(10px)",
    [theme.breakpoints.down("md")]: {
      padding: props.mobilePadding ? props.mobilePadding : "16px 16px 24px",
    },
  },
  content: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 1280,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  bg: {
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    svg: {
      minHeight: 80,
      height: "100%",
    },
  },
}));

interface StickyHeaderWrapperProps {
  children: React.ReactNode;
  mobilePadding?: string;
}

const StickyHeaderWrapper = ({
  children,
  mobilePadding,
}: StickyHeaderWrapperProps) => {
  const theme: ITheme = useTheme();
  const classes = useStyles({
    mobilePadding,
  });
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>{children}</Box>
      <Box className={classes.bg}>
        <Bg mode={theme.palette.mode} />
      </Box>
    </Box>
  );
};

export default StickyHeaderWrapper;

function Bg({ mode }) {
  switch (mode) {
    case "dark":
      return <BgDark />;
    case "light":
      return <BgLight />;
    default:
      return null;
  }
}
